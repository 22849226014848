/**
 * @Author: giligili
 * @Date: 2021-08-03
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-19 16:55:31
 */

import * as RouterName from 'collection-admin-web/common/constant/router-name';

export default {
    app: RouterName.CaseAppLoginHistory,
    case: RouterName.CaseDetail,
    lawsuit: RouterName.CaseLawsuitDetail,
    remindCase: RouterName.RemindCaseDetail,
    audit: RouterName.AuditMonitor,
    remind: RouterName.CaseRemind,
    account: RouterName.AccountApplyDetail,
    accountManager: RouterName.AccountManager,
    assistInitiate: RouterName.CaseOwnAssistStart,
    assistReceive: RouterName.CaseOwnAssistReceived,
    qualityReview: RouterName.QualityReviewDetail,
    qualityReviewFlow: RouterName.QualityReviewFlow,
    repairDetail: RouterName.RepairQuailtyDetail,
    complaintDetail: RouterName.ComplaintQualityDetail,
    feedbackRecord: RouterName.FeedbackRecord,
    workStatusNotification: RouterName.WorkStatusNotification,
    ruleCodeRecord: RouterName.RuleCodeRecord,
    ruleCodeRelation: RouterName.RuleCodeRelation,
};
