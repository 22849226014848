<!-- @Author: giligili -->
<!-- @Date: 2021-08-03 11:54:02.887 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-30 12:48:08 -->

<template>
    <span
        v-if="isShow"
        class="yqg-router-link"
    >
        <template v-if="canLink">
            <router-link
                v-if="!hash"
                :to="{
                    query,
                    params,
                    name: toRouterName
                }"
                rel="opener"
                :target="target"
            >
                <slot>{{ text }}</slot>
            </router-link>

            <a
                v-else
                @click="routerLink"
            ><slot>{{ text }}</slot></a>
        </template>

        <span v-else><slot>{{ text }}</slot></span>
    </span>
</template>

<script type="text/babel">

    import RouterNameMap from './constant/router-name-map';

    export default {
        name: 'YqgRouterLink',

        props: {
            target: {
                type: String,
                default: '_blank'
            },

            query: {
                type: Object,
                default: () => {}
            },

            params: {
                type: Object,
                default: () => {}
            },

            hash: {
                type: String,
                default: undefined
            },

            to: {
                type: String,
                required: true
            },

            text: {
                type: String,
                default: '查看详情'
            },

            noAuthHide: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            toRouterName() {
                return RouterNameMap[this.to];
            },

            // 权限判断
            canLink() {
                const {toRouterName} = this;

                const toRouter = this.$router.resolve({name: toRouterName});

                const {route: {meta: {yqgPrivileges = []}}} = toRouter;

                return this.$app.isAuthorized(yqgPrivileges);
            },

            isShow() {
                // 不能跳转并且无权限隐藏时 隐藏
                if (!this.canLink && this.noAuthHide) {
                    return false;
                }

                return this.toRouterName;
            }
        },

        methods: {
            routerLink() {
                const {toRouterName, query, hash, target} = this;

                const {href} = this.$router.resolve({name: toRouterName, query});

                window.open(`${href}#${hash}`, target);
            }
        }
    };

</script>
