import { methodIntercept } from '../helper';
import HubClient from '../hub/client';
import Message from './message';
class SocketHubClient extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: SocketHubClient.clientName }));
        this.onMessage = (event) => {
            const data = event.data;
            const message = new Message({ data, direction: 'INCOMING' });
            this.client.emit('RECEIVE_MESSAGE', {
                data,
                message,
            });
        };
        this.onDisconnected = (body) => {
            const userDispatched = !body.error;
            const { registerCallId } = this;
            // 坐席主动关闭 websocket, 调用了 stop 方法
            if (userDispatched && this.ws && this.ws.readyState !== this.ws.CLOSED) {
                this.client.emit('DISCONNECTED', {
                    reason: 'USER_CLOSE',
                    registerCallId,
                });
                return;
            }
            if (!userDispatched) {
                let closeInfo = body.error;
                closeInfo = Object.assign(Object.assign({}, closeInfo), { wasClean: !closeInfo.error });
                this.client.emit('DISCONNECTED', {
                    reason: 'EXCEPTION_CLOSE',
                    closeInfo,
                    registerCallId,
                });
            }
        };
        const { device, hubService } = options;
        this.device = device;
        this.init();
        hubService.getClient('registerClient', (client) => {
            this.registerCallId = client.registerCallId;
        });
        super.register();
    }
    init() {
        // 拦截 transport 的 send 方法
        this.initTransportSend();
        // 监听 ws 的 onMessage 
        this.initWsOnMessage();
        // 监听 dissonnected
        this.disconnectListener();
    }
    initWsOnMessage() {
        const socket = this.device._transport.socket; // eslint-disable-line
        this.ws = socket._ws; // eslint-disable-line
        this.ws.addEventListener('message', this.onMessage);
    }
    initTransportSend() {
        const transport = this.device._transport; // eslint-disable-line
        const send = transport.send;
        this.device._transport.send = methodIntercept(// eslint-disable-line
        this.transportSend.bind(this, transport), send.bind(transport), (err) => {
            this.client.emit('SDK_ERROR', {
                reason: 'INTERCEPT_TRANSPORT_SEND_ERROR',
                message: `拦截 socket send 方法异常 ${err === null || err === void 0 ? void 0 : err.name}: ${err === null || err === void 0 ? void 0 : err.message}`,
            });
        });
    }
    transportSend(transport, payload) {
        const data = payload.toString();
        const message = new Message({ data, direction: 'OUTGOING' });
        // 断网
        if (!window.navigator.onLine) {
            this.client.emit('SEND_MESSAGE_FAIL', {
                data,
                message,
                reason: 'NETWORK_OFFLINE',
            });
            return;
        }
        // websocket 关闭
        if (!transport.isConnected()) {
            this.client.emit('SEND_MESSAGE_FAIL', {
                data,
                reason: 'WEKSOCKET_NOT_OPEN',
                message,
            });
            return;
        }
        // 发消息
        this.client.emit('SEND_MESSAGE', {
            data,
            message,
        });
    }
    disconnectListener() {
        this.device.addListener('disconnected', this.onDisconnected);
    }
    destroy() {
        var _a;
        (_a = this.ws) === null || _a === void 0 ? void 0 : _a.removeEventListener('message', this.onMessage);
        this.device.removeListener('disconnected', this.onDisconnected);
    }
}
SocketHubClient.clientName = 'socketClient';
export default SocketHubClient;
