import JsSIP from 'jssip';
const delimiter = '\r\n';
const parseFirstLine = (line) => {
    const { method, status_code: statusCode } = JsSIP.Grammar.parse(line, 'Request_Response');
    return { method, statusCode };
};
const parseMethod = (lines) => {
    const line = lines.find(line => /cseq/i.test(line));
    if (!line)
        return '';
    const { value } = JsSIP.Grammar.parse(line, 'CSeq');
    return value;
};
const parseCallId = (lines) => {
    const line = lines.find(line => /call-id/i.test(line));
    if (!line)
        return '';
    return line.replace(/call-id: */i, '');
};
const parseIgnoreCollection = (lines) => {
    return lines.some(line => /Network_Quality_Check/.test(line));
};
class Message {
    constructor(options) {
        var _a;
        const { data, direction } = options;
        const lines = data.split(delimiter);
        this.data = data;
        this.direction = direction;
        const { method, statusCode } = parseFirstLine(lines[0]);
        this.type = method === 'ACK'
            ? 'ACK'
            : (statusCode ? 'RESPONSE' : 'REQUEST');
        this.method = method || parseMethod(lines);
        this.statusCode = statusCode;
        this.ignoreCollection = parseIgnoreCollection(lines);
        this.id = (_a = /Via.+branch=(.+)(\r\n|;)/.exec(data)) === null || _a === void 0 ? void 0 : _a[1];
        this.callId = parseCallId(lines);
    }
}
export default Message;
