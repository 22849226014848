
/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 回拨
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

import mixinGetCaseDetail from '../../mixin/get-case-detail';
import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class InboundType extends BasicType {

    type = 'INBOUND';

    constructor(props) {
        super(props);

        this.title = '【回拨电话】';
        this.encryptedMobileNumber = aesEncryptCallSecret(
            this.sharedData.incomingMobileNumber
        );

        this.extraData.dialType = CallDialType.DIAL_BACK;
        this.extraData.encryptedMobileNumber = this.encryptedMobileNumber;
    }

    /* @desc override */
    getSharedData() {
        const {sharedDataRet} = this;

        return sharedDataRet.promise;
    }

}

Object.assign(InboundType.prototype, mixinGetCaseDetail);
