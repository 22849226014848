var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-call-center" }, [
    !_vm.isLogin
      ? _c("button", { staticClass: "sm", on: { click: _vm.openLoginModal } }, [
          _vm._v("\n        外呼登录\n    "),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.isLogin
      ? _c(
          "div",
          [
            _vm.quality.score || _vm.quality.score === 0
              ? _c(
                  "div",
                  { style: { marginBottom: "10px" } },
                  [
                    _c(
                      "b-popper",
                      {
                        attrs: {
                          visible: _vm.networkInfoVisible,
                          placement: "left",
                        },
                      },
                      [
                        _c("div", { staticClass: "network-info" }, [
                          _vm.quality.sipLostRate
                            ? _c("div", { staticClass: "network-info-item" }, [
                                _vm._v(
                                  "\n                        sip 丢包率: " +
                                    _vm._s(_vm.quality.sipLostRate) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.quality.sipRTT
                            ? _c("div", { staticClass: "network-info-item" }, [
                                _vm._v(
                                  "\n                        sip 延迟: " +
                                    _vm._s(_vm.quality.sipRTT) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.quality.rtpLostRate
                            ? _c("div", { staticClass: "network-info-item" }, [
                                _vm._v(
                                  "\n                        rtp 丢包率: " +
                                    _vm._s(_vm.quality.rtpLostRate) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.quality.rtpRTT
                            ? _c("div", { staticClass: "network-info-item" }, [
                                _vm._v(
                                  "\n                        rtp 延迟: " +
                                    _vm._s(_vm.quality.rtpRTT) +
                                    "\n                    "
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "network-quality",
                        style: { cursor: "pointer" },
                        on: {
                          mouseenter: function ($event) {
                            _vm.networkInfoVisible = true
                          },
                          mouseleave: function ($event) {
                            _vm.networkInfoVisible = false
                          },
                        },
                      },
                      [
                        _c("wifi", { attrs: { signal: _vm.quality.level } }),
                        _vm._v(" "),
                        _c("span", { style: { color: _vm.quality.color } }, [
                          _vm._v(_vm._s(_vm.quality.score)),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isConnected
              ? _c("keyboard", {
                  ref: "keyboard",
                  on: { change: _vm.sendDtmf },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.canCall && _vm.validCallIn
              ? _c(
                  "div",
                  {
                    staticClass: "ytalk-status-content",
                    class: {
                      "text-success": _vm.isAvailable,
                      "text-info": _vm.isBusy,
                    },
                  },
                  [
                    _vm._v(
                      "\n            当前坐席状态：" +
                        _vm._s(_vm.AgentStatusMap[_vm.agentInfo.status]) +
                        "\n\n            "
                    ),
                    _vm.isBusy
                      ? _c(
                          "button",
                          {
                            staticClass: "sm default",
                            on: {
                              click: function ($event) {
                                return _vm.updateAgentStatus(
                                  _vm.AgentStatus.AVAILABLE
                                )
                              },
                            },
                          },
                          [_vm._v("\n                空闲\n            ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAvailable
                      ? _c(
                          "button",
                          {
                            staticClass: "sm info",
                            on: {
                              click: function ($event) {
                                return _vm.updateAgentStatus(
                                  _vm.AgentStatus.BUSY
                                )
                              },
                            },
                          },
                          [_vm._v("\n                忙碌\n            ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._t("echoTest"),
                    _vm._v(" "),
                    _vm.canConfig
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip",
                              value: "坐席设置",
                              expression: "'坐席设置'",
                            },
                          ],
                          staticClass: "b-icon-setting",
                          staticStyle: { color: "#ccc" },
                          on: { click: _vm.openConfigModal },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "ytalk-call-content" }, [
              _vm.canCall
                ? _c("div", { staticClass: "call-content" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.agentInfo.mobileNumber,
                          expression: "agentInfo.mobileNumber",
                        },
                      ],
                      attrs: { type: "text", placeholder: "请输入对方号码" },
                      domProps: { value: _vm.agentInfo.mobileNumber },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.agentInfo,
                            "mobileNumber",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "sm",
                        on: {
                          click: function ($event) {
                            return _vm.call({
                              mobileNumber: _vm.agentInfo.mobileNumber,
                            })
                          },
                        },
                      },
                      [_c("i", { staticClass: "b-icon-phone" })]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.canHangUp
                ? _c(
                    "div",
                    { staticClass: "hangup-content" },
                    [
                      _vm.isEavesdrop
                        ? [
                            _c("span", { staticClass: "connect" }, [
                              _vm._v(
                                "\n                        监听中\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "sm danger",
                                on: { click: _vm.hangup },
                              },
                              [
                                _vm._v(
                                  "\n                        挂断\n                    "
                                ),
                              ]
                            ),
                          ]
                        : _vm.isCallTest
                        ? [
                            _c("span", { staticClass: "connect" }, [
                              _vm._v(
                                "\n                        回声测试中\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "sm danger",
                                on: { click: _vm.hangup },
                              },
                              [
                                _vm._v(
                                  "\n                        结束测试\n                    "
                                ),
                              ]
                            ),
                          ]
                        : [
                            _vm._t("default"),
                            _vm._v(" "),
                            _vm._t("input"),
                            _vm._v(" "),
                            _c(
                              "span",
                              { class: { connect: _vm.isConnected } },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm._f("yqgPhoneMask")(_vm.displayPhone)
                                    ) +
                                    "\n                        " +
                                    _vm._s(
                                      _vm.isConnected
                                        ? "通话中"
                                        : _vm.canAnswer
                                        ? "来电中"
                                        : "呼叫中"
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isConnected
                              ? _c("span", { staticClass: "connected-ops" }, [
                                  !_vm.isMuted
                                    ? _c(
                                        "button",
                                        {
                                          staticClass: "sm normal",
                                          on: { click: _vm.mute },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "b-icon-micro",
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "button",
                                        {
                                          staticClass: "sm normal",
                                          on: { click: _vm.unmute },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "b-icon-micro-silence",
                                          }),
                                        ]
                                      ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "sm normal",
                                      on: { click: _vm.toggleKeyboard },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "keyboard",
                                        attrs: { src: _vm.KeyboardIcon },
                                      }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canAnswer
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "sm",
                                    on: { click: _vm.answer },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        接听\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "sm danger",
                                on: { click: _vm.hangup },
                              },
                              [
                                _vm._v("\n                        挂断"),
                                _vm.hangupCountDown && _vm.hangupCountDown > 0
                                  ? _c("span", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.hangupCountDown) + "s)"
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.canaMnualTransferSatis
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "sm default",
                                    on: { click: _vm.transfer },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        挂断转接满意度\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                : _c(
                    "button",
                    {
                      staticClass: "sm default logout-content",
                      on: { click: _vm.unRegister },
                    },
                    [_vm._v("\n                登出\n            ")]
                  ),
            ]),
            _vm._v(" "),
            _c("audio", {
              staticStyle: { display: "none" },
              attrs: { id: "YTalkRemoteAudio", autoplay: "autoplay" },
            }),
            _vm._v(" "),
            _c("audio", {
              staticStyle: { display: "none" },
              attrs: { id: "YTalkHangupRecord", src: _vm.HangupRecord },
            }),
            _vm._v(" "),
            _c("audio", {
              staticStyle: { display: "none" },
              attrs: { id: "YTalkContentedRecord", src: _vm.ContentedRecord },
            }),
            _vm._v(" "),
            _c("audio", {
              staticStyle: { display: "none" },
              attrs: {
                id: "YTalkRingRecord",
                loop: "loop",
                src: _vm.RingRecord,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }