/*
 * @Author: xiaodongyu
 * @Date 2020-08-24 11:06:32
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-25 21:05:34
 */
/* eslint-disable camelcase, no-undef */

import {BroadcastChannel} from 'broadcast-channel';
import JsSIP from 'jssip';
import _ from 'underscore';

import {aesEncryptCallSecret as aesEncrypt} from 'collection-admin-web/common/util/encryption';
import {toCamelCase} from 'collection-admin-web/common/util/tool';

import AgentStatus from './constant/agent-status';
import MessageType from './constant/message-type';
import SipMessageType from './constant/sip-message-type';
import ActiveActionCallbackManager from './helper/active-action-callback-manager';

const DefaultMediaConstraints = {
    audio: {
        mandatory: {
            googNoiseSuppression: true,
            googNoiseSuppression2: true,
            googEchoCancellation: true,
            googHighpassFilter: true
        }
    },
    video: false
};

const DefaultReconnectMaxTimes = 5;

const activeActionCallbackManager = new ActiveActionCallbackManager();

export default {
    data() {
        return {
            AgentStatus,
            shared: {
                status: AgentStatus.INIT,
                startTime: 0,

                // outbound
                mobileNumber: '',
                encryptedMobileNumber: '',

                // inbound
                incomingMobileNumber: '',

                // gaf
                handleTime: 0,

                // work status
                workStatus: '',
                workStatusMap: [],
                workStatusLoading: false,
            }
        };
    },

    computed: {
        displayPhone() {
            const {direction, mobileNumber, incomingMobileNumber} = this.shared;

            return direction === 'outgoing' ? mobileNumber : incomingMobileNumber;
        },

        canCall() {
            const {LOGIN, AVAILABLE, BUSY} = AgentStatus;

            return [LOGIN, AVAILABLE, BUSY].includes(this.shared.status);
        },

        canAnswer() {
            return this.shared.status === AgentStatus.OFFERING;
        },

        canHangUp() {
            const {CALLING, CONNECTED, DIALING, OFFERING} = AgentStatus;

            return [CALLING, CONNECTED, DIALING, OFFERING].includes(this.shared.status);
        },

        isLogin() {
            const {status} = this.shared;

            return (status !== AgentStatus.INIT)
                && (status !== AgentStatus.LOGOUT)
                && (status !== AgentStatus.REGISTERING);
        },

        isRegistering() {
            const {status} = this.shared;

            return (status === AgentStatus.REGISTERING);
        },

        isConnected() {
            return this.shared.status === AgentStatus.CONNECTED;
        },

        isAvailable() {
            return this.shared.status === AgentStatus.AVAILABLE;
        },

        isBusy() {
            return this.shared.status === AgentStatus.BUSY;
        },

        mediaConstraints() {
            return this.mediaConstraintsSelf || this.$options.MediaConstraints || DefaultMediaConstraints;
        },

        reconnectMaxTimes() {
            return this.$options.ReconnectMaxTimes || DefaultReconnectMaxTimes;
        }
    },

    mounted() {
        this.openChannel();
    },

    destroyed() {// github.com/pubkey/broadcast-channel/blob/master/src/broadcast-channel.js
        this.handleUserAction(MessageType.unregister); // 登出坐席
        this.closeChannel();
    },

    methods: {
        openChannel() {
            this.broadcastChannel = new BroadcastChannel('ytalk');

            this.broadcastChannel.onmessage = this.onChannelMessage;

            this.handleUserAction(MessageType.request);
        },

        closeChannel() {
            const {broadcastChannel: channel} = this;
            channel.onmessage = null;
            setTimeout(() => channel.close(), 100);
        },

        postMessage(msg) {
            // ref: https://github.com/pubkey/broadcast-channel/blob/master/src/broadcast-channel.js
            if (this.broadcastChannel.closed) {
                this.openChannel(); // 意外close, 重新open下

                try {
                    this.logger('broadcastchannel:close', '页面广播渠道意外关闭', {msg});
                } catch (err) {
                    // ignore
                }
            }

            try {
                this.broadcastChannel.postMessage(msg);
            } catch (err) {
                if (typeof YqgReporter !== 'undefined') {
                    YqgReporter.fatalException(msg, err);

                    return;
                }

                throw err;
            }
        },

        /* 坐席行为 callback start */
        // 创建 webSocket 链接的页面
        handleUserAction(type, payload) {
            const prefix = 'user';
            const method = toCamelCase(prefix, type);
            const action = {method, payload, type: prefix};

            this.onChannelMessage(action);
            this.postMessage(action);
        },

        // 当前聚焦(如果没有聚焦在当前系统，则在下一次聚焦当前系统时触发)
        handleActiveAction(type, payload) {
            const prefix = 'active';
            const method = toCamelCase(prefix, type);
            const action = {method, payload, type: prefix};

            this.onChannelMessage(action);
            this.postMessage(action);
        },

        // 所有 tab
        handleAllAction(type, payload) {
            const prefix = 'all';
            const method = toCamelCase(prefix, type);
            const action = {method, payload, type: prefix};

            this.onChannelMessage(action);
            this.postMessage(action);
        },
        /* 坐席行为 callback end */

        syncShared() {
            const cb = () => {
                this.shared = {...this.shared};

                this.postMessage({
                    type: 'all',
                    method: 'allSync',
                    payload: this.shared
                });
            };

            return Promise.resolve().then(cb);
        },

        onChannelMessage({type, method, payload}) {
            const vm = this;

            const actionCallbackMap = {
                all() {
                    if (!vm[method]) return;

                    vm[method](payload);
                },

                user() {
                    if (!vm[method] || !vm.userAgent) return;

                    vm[method](payload);
                },

                active() {
                    if (!vm[method]) return;

                    activeActionCallbackManager.runActionCallback({
                        type: method,
                        payload,
                        callback: (...vals) => {
                            vm[method](...vals);

                            vm.onChannelMessage({type: 'cancel', method});
                            vm.postMessage({type: 'cancel', method});
                        }
                    });
                },

                cancel() {
                    if (!vm[method]) return;

                    activeActionCallbackManager.cancelActionCallback({
                        type: method
                    });
                }
            };

            actionCallbackMap[type]();
        },

        handleAlertSipMessage(responseBody) {
            const finallyResponseBody = responseBody || {};
            const {sipMessageType, body} = finallyResponseBody;

            if (sipMessageType !== SipMessageType.ALERT) return;

            // 默认提示
            this.handleAllAction('notification', {
                content: body,
                title: '提醒',
                duration: 2,
                iconType: 'default',
            });
        },

        initSip(loginInfo = {}) {
            try {
                if (this.shared.status === AgentStatus.REGISTERING) return;
                Object.assign(this.shared, {status: AgentStatus.REGISTERING});
                this.syncShared();
                // if (this.userAgent) this.userAgent.stop();
                const {callAccount: account, callPassword: password, socket, uriHost, uaConfiguration = {}} = loginInfo;

                const socketUrl = `${socket}?userId=${account}`;
                const webSocket = new JsSIP.WebSocketInterface(socketUrl);

                this.uriHost = uriHost;
                const configuration = {
                    password,
                    sockets: [webSocket],
                    uri: `sip:${account}@${uriHost}`,
                    register: true, // auto register when start
                    session_timers: false,
                    contact_uri: `sip:${account}@${uriHost};transport=wss`,
                    connection_recovery_min_interval: 4,
                    ...uaConfiguration
                };
                this.userAgent = new JsSIP.UA(configuration);

                this.userAgent.on('registered', data => {
                    this.$message.success(this.$t('ytalk.loginSuccess'));
                    this.shared = {
                        ...this.shared,
                        registerCallId: data?.response?.call_id,
                        status: AgentStatus.BUSY
                    };
                    Object.assign(this.shared, {status: AgentStatus.BUSY});
                    this.setWindowCloseEvent();
                    if (this.onRegisterSuccess) this.onRegisterSuccess();
                    this.syncShared();
                });
                this.userAgent.on('unregistered', () => {
                    this.$message.info(this.$t('ytalk.logoutSuccess'));
                    if (this.userAgent) {
                        this.userAgent.stop();
                        this.userAgent = null;
                    }

                    this.reset({status: AgentStatus.LOGOUT});
                    this.resetWindowCloseEvent();
                    if (this.onUnregistered) this.onUnregistered();
                });
                this.userAgent.on('registrationFailed', data => {
                    Object.assign(this.shared, {status: AgentStatus.LOGOUT});
                    if (this.onRegisterFailed) this.onRegisterFailed(data);
                    this.syncShared();
                });
                this.userAgent.on('newRTCSession', async newRTCSessionData => {
                    this.logger('mixin:newRTCSession', 'ytalk mixin 发送/收到INVITE消息');

                    const {session} = newRTCSessionData;

                    this.currentSession = session;
                    this.addstream();
                    session.on('accepted', sessionData => {
                        const {autoAnswer, isEavesdrop, isMultiCall} = this.shared;
                        // 一键多呼先由ytalk自动接通坐席，此时客户不一定接通
                        if (isEavesdrop || isMultiCall) return;

                        Object.assign(this.shared, {
                            status: AgentStatus.CONNECTED,
                            connected: true,
                            startTime: Date.now()
                        });
                        if (this.onSessionAccepted) this.onSessionAccepted(sessionData);
                        if (this.onSessionConnected) this.onSessionConnected();

                        this.syncShared().then(() => this.handleActiveAction(MessageType.callConnected));
                        if (autoAnswer) return;

                        const {YTalkRingRecord} = this.$refs;
                        if (YTalkRingRecord) {
                            YTalkRingRecord.pause();
                            YTalkRingRecord.currentTime = 0;
                        }
                    });
                    session.on('confirmed', sessionData => {
                        // 一键多呼先由ytalk自动接通坐席，此时客户不一定接通
                        if (this.shared.isMultiCall) return;
                        Object.assign(this.shared, {
                            status: AgentStatus.CONNECTED,
                            connected: true
                        });
                        if (this.onSessionConfirmed) this.onSessionConfirmed(sessionData);
                        this.syncShared();
                    });
                    session.on('progress', sessionData => {
                        const {response} = sessionData;
                        if (response) {
                            const callId = response.getHeader('X-Call-Uuid');
                            if (callId) {
                                Object.assign(this.shared, {callId});
                                if (this.onSessionProgress) this.onSessionProgress(sessionData); // 仅呼出
                                this.syncShared();
                            }
                        }
                    });
                    session.on('ended', this.callRelease);
                    session.on('failed', (...vals) => {
                        this.callRelease(...vals);

                        if (this.onSessionFailed) this.onSessionFailed(...vals);
                    });

                    const shared = this.parseNewRTCSession(newRTCSessionData);
                    Object.assign(this.shared, shared);
                    if (this.onNewRTCSession) this.onNewRTCSession(newRTCSessionData);
                    this.syncShared();
                });
                this.userAgent.on('newMessage', messageData => {
                    const body = JSON.parse(messageData.request.body);

                    this.handleActiveAction(MessageType.newMessage, body);
                    if (this.onNewMessage) this.onNewMessage(body, messageData);

                    const {sipMessageType, curStatus, callId} = body;
                    this.handleAlertSipMessage(body);

                    if (sipMessageType === 'LOGIN_CHECK') {
                        if (this.shared.registerCallId !== callId) {
                            this.handleAllAction('toast', '当前坐席已在其他设备或浏览器登录, 请尝试重启浏览器重新登录');
                            this.userAgent.unregister({all: false});
                            this.logger('LOGIN_CHECK', '异地登陆');
                        }
                    }

                    if (sipMessageType === 'USER_STATUS_SYNC' && curStatus === 'HANGUP_HANDLE') {
                        this.reset(); // 不用交互的, ua tab 直接处理
                    }

                    const {multiAnswerMobile, callBackParam} = body;
                    if (this.shared.isMultiCall && multiAnswerMobile && callBackParam) {
                        Object.assign(this.shared, {
                            status: AgentStatus.CONNECTED,
                            connected: true,
                            startTime: Date.now(),
                            encryptedMobileNumber: aesEncrypt(multiAnswerMobile),
                            callBackParam
                        });
                        if (this.onSessionConnected) this.onSessionConnected();
                        this.syncShared().then(() => this.handleActiveAction(MessageType.callConnected));
                        const {YTalkCallRecord} = this.$refs;
                        if (YTalkCallRecord) {
                            YTalkCallRecord.pause();
                            YTalkCallRecord.currentTime = 0;
                        }

                        return;
                    }

                    // 满意度，强制退出挂断
                    const {
                        satisfactionTemplateId: templateId,
                        transferSatisType: transferType,
                        hangupHandleTime: handleTime
                    } = body;
                    if (templateId || transferType) {
                        Object.assign(this.shared, {
                            templateId,
                            transferType,
                            handleTime
                        });
                        this.syncShared();
                    }
                });
                this.userAgent.on('disconnected', data => {
                    // 后端主动关闭 socket，这时候不需要重试
                    if (data?.error?.code === 4001 && this.userAgent) {
                        this.userAgent.stop();

                        return;
                    }

                    if (this.userAgent) {
                        /* eslint-disable no-underscore-dangle */
                        if (this.userAgent._transport.recover_attempts >= this.reconnectMaxTimes) {
                            this.userAgent.unregister({all: false});
                            this.userAgent.stop();
                            this.userAgent = null;
                            this.resetWindowCloseEvent();
                            this.reset({status: AgentStatus.INIT});

                            if (typeof YqgReporter !== 'undefined') {
                                YqgReporter.fatalException(data);
                            }
                        }
                    }
                });

                this.userAgent.start(); // 初始化
            } catch (err) {
                this.$message.error(this.$t('ytalk.initializeFailed'));
            }
        },

        addstream() {
            const {connection} = this.currentSession;
            if (!connection) return;

            connection.addEventListener('addstream', data => {
                const {YTalkRemoteAudio} = this.$refs;
                if (YTalkRemoteAudio) {
                    YTalkRemoteAudio.srcObject = data.stream;
                    YTalkRemoteAudio.play();
                    if (this.addstreamSelf) this.addstreamSelf(data.stream);
                }
            });
        },

        parseNewRTCSession({originator, session, request}) {
            const {direction} = session;
            let status = AgentStatus.DIALING;
            if (originator === 'local') { // 呼出
                return {
                    direction,
                    status,
                    callDirection: 'CALL_OUT'
                };
            }

            const callType = request.getHeader('X-call-type');
            let autoAnswer = request.getHeader('X-Auto-Answer') === 'true'; // 自动接听header
            const isMultiCall = request.getHeader('X-call-type') === 'MULTI'; // 一键多呼header
            const isEavesdrop = request.getHeader('X-eavesdrop') === 'true'; // 监听自动接听header
            const incomingMobileNumber = request.getHeader('X-PHONE-NUMBER');
            const incomingEncryptedMobileNumber = request.getHeader('X-E-PHONE-NUMBER'); // 加密手机号 TODO:推动ytalk改一下回拨也用这个, 不明文传输手机号...
            const skillGroupName = request.getHeader('X-TRANSFER_ROUTE'); // 转接技能组展示
            const templateId = request.getHeader('X-SATIS-TEMPLATE-ID');
            const transferType = request.getHeader('X-SATIS-TRANSFER-TYPE');
            const handleTime = Number(request.getHeader('X-hangupHandleTime')) || 0; // 话后处理时长
            const menuKeyIntent = request.getHeader('X-MENU-KEY-INTENT'); // 进线信息
            const callId = request.getHeader('X-Call-Uuid');
            let callDirection = 'CALL_IN';
            let customData = null;
            let predictiveCustomData = null;

            autoAnswer = this.isAutoAnswerAvailable() && autoAnswer || callType === 'CALL_TEST';

            if (isMultiCall || autoAnswer || isEavesdrop) {
                const {mediaConstraints} = this;
                session.answer({mediaConstraints});
                this.addstream();
                if (isMultiCall) {
                    customData = request.getHeader('X-Multi-Param'); // 一键多呼
                    callDirection = 'CALL_OUT'; // 催收一键多呼的设置
                }
            } else {
                status = AgentStatus.OFFERING;
                predictiveCustomData = request.getHeader('X-Predictive-Attach'); // 预测外呼
                this.$refs.YTalkRingRecord.play();
            }

            return {
                direction,
                status,
                callDirection,
                autoAnswer,
                isEavesdrop,
                isMultiCall,
                incomingMobileNumber,
                incomingEncryptedMobileNumber,
                skillGroupName,
                templateId,
                transferType,
                handleTime,
                menuKeyIntent,
                callId,
                customData,
                predictiveCustomData,
                callType
            };
        },

        callRelease(data) {
            // 线路失败原因提示
            const gatewayHangupCause = data.message?.getHeader('Gateway-Hangup-Cause');
            // gaf强制转接弹窗提醒
            const transferOperateUserId = data.message?.getHeader('X-TransferOperateUserId');
            // 需要交互的在 active tab 处理
            this.handleActiveAction(MessageType.callEnd, {transferOperateUserId, gatewayHangupCause});

            this.handleUserAction('callEnd');

            if (this.onCallEnd) this.onCallEnd(data);
        },

        reset(shared = {}) {
            this.shared = {
                registerCallId: this.shared.registerCallId,
                status: AgentStatus.BUSY,
                startTime: 0,

                // outbound
                mobileNumber: '',
                encryptedMobileNumber: '',
                maskedMobileNumber: '',

                // inbound
                incomingMobileNumber: '',

                // gaf
                handleTime: 0,

                // 如果传入status要么同时设置了workStatus，要么需要workStatus清空
                // 否则 工作状态应该和坐席状态保持一致为BUSY
                workStatus: shared.status ? '' : this.busyId,
                workStatusMap: this.shared.workStatusMap,
                workStatusLoading: false,
                ...shared
            };
            this.syncShared();
            this.currentSession = null;

            const {YTalkHangupRecord, YTalkRingRecord, YTalkCallRecord, YTalkRemoteAudio} = this.$refs;
            if (YTalkHangupRecord) {
                YTalkHangupRecord.play();
            }

            if (YTalkRingRecord) {
                YTalkRingRecord.pause();
                YTalkRingRecord.currentTime = 0;
            }

            if (YTalkCallRecord) {
                YTalkCallRecord.pause();
                YTalkCallRecord.currentTime = 0;
            }

            if (YTalkRemoteAudio) {
                YTalkRemoteAudio.pause();
                YTalkRemoteAudio.srcObject = null;
            }
        },

        setWindowCloseEvent() {
            const {userAgent} = this;
            if (userAgent) {
                // 注册页关闭提示
                window.onbeforeunload = event => {
                    if (!userAgent) return false;
                    userAgent.unregister({all: false});
                    this.reset({status: AgentStatus.INIT});
                    const ev = event || window.event;
                    if (ev) ev.returnValue = this.$t('ytalk.pageClosed');

                    return this.$t('ytalk.pageClosed');
                };
            }
        },

        resetWindowCloseEvent() {
            window.onbeforeunload = null;
        },

        isAutoAnswerAvailable() {
            // covert antoAnswer to manual answer in firefox not focus
            if (navigator.userAgent.indexOf('Firefox') > -1 && !document.hasFocus()) {
                return false;
            }

            return true;
        },

        async isValidMediaDevices() {
            try {
                await new Promise((resolve, reject) => {
                    if (!navigator.getUserMedia && !navigator.mediaDevices.getUserMedia) {
                        reject();
                    }

                    const mediaStreamPromise = navigator.mediaDevices.getUserMedia(this.mediaConstraints);
                    mediaStreamPromise.then(mediaStream => {
                        const tracks = mediaStream.getTracks();

                        tracks.forEach(track => track.stop());
                    });

                    resolve(mediaStreamPromise);
                });

                return true;
            } catch (err) {
                this.$error({title: this.$t('ytalk.microphoneEnabled')});
                if (typeof YqgReporter !== 'undefined') {
                    YqgReporter.fatalException('Media Device Exception', err);
                }

                return false;
            }
        },

        // 同步 ua tab 上同步数据
        userRequest(payload = {}) {
            this.shared = {...this.shared, ...payload};

            this.syncShared();
        },

        // 打电话
        async userCall(payload) {
            const {userAgent, mediaConstraints} = this;

            const validMedia = await this.isValidMediaDevices();
            if (!validMedia) return;

            const {canCall} = this;
            const {encryptedMobileNumber, sceneOutgoing, callShared} = payload;
            let {mobileNumber} = payload;
            if (mobileNumber) {
                mobileNumber = mobileNumber.trim();
            }

            if ((!mobileNumber && !encryptedMobileNumber) || !canCall) return;

            const callNumber = encryptedMobileNumber || `0${mobileNumber}`;

            Object.assign(this.shared, {
                mobileNumber,
                encryptedMobileNumber,
                callShared
            });

            this.currentSession = userAgent.call(`sip:${callNumber}@${this.uriHost}`, {
                mediaConstraints,
                ...(sceneOutgoing ? {extraHeaders: [`X-Scene: ${sceneOutgoing}`]} : {})
            });

            Object.assign(this.shared, {
                status: AgentStatus.DIALING
            });
            this.syncShared();
        },

        userUnregister() {
            this.userAgent.unregister({all: false});
        },

        userTerminateSessions() {
            this.userAgent.terminateSessions();
        },

        userAnswer() {
            const {currentSession, mediaConstraints} = this;

            currentSession.answer({mediaConstraints});

            this.addstream();
        },

        userMute(payload) {
            const type = 'mute';

            const {currentSession} = this;

            const silent = payload; // silent 为 true, 只是前端静音，不调预留方法 this[type]

            currentSession[type]();

            if (!silent && this[type]) this[type]();

            const {audio: isMuted} = currentSession.isMuted();

            Object.assign(this.shared, !silent ? {isMuted} : {isSilentMuted: isMuted});

            this.syncShared();
        },

        userUnmute(payload) {
            const type = 'unmute';

            const {currentSession} = this;

            const silent = payload; // silent 为 true, 只是前端静音，不调预留方法 this[type]

            currentSession[type]();

            if (!silent && this[type]) this[type]();

            const {audio: isMuted} = currentSession.isMuted();

            Object.assign(this.shared, !silent ? {isMuted} : {isSilentMuted: isMuted});

            this.syncShared();
        },

        userSendDTMF(payload) {
            const info = payload;

            this.currentSession.sendDTMF(info);
        },

        allNotification(payload) {
            const finallyPayload = payload || {};
            const {title, content, duration, iconType} = finallyPayload;

            const iconMap = {
                default: <a-icon type="exclamation-circle" style="color: #8B0000" />
            };

            this.$notification.open({
                description: content,
                message: title,
                duration,
                icon: iconType && iconMap[iconType]
            });
        },

        allToast(payload) {
            this.$message.info(payload);
        },

        allSync(payload) {
            if (!_.isEqual(payload, this.shared)) {
                this.shared = payload;
            }
        }
    }
};
