var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { STAGE } from '@shared/common/constant/stage';
const defaultParams = {
    app: 'octopus',
    stage: STAGE,
};
const TEST_URL = 'https://octopus-api-test.yangqianguan.com/api-web/hostCond';
const CN_PROD_URL = 'https://octopus-api.yangqianguan.com/api-web/hostCond';
const MEX_PROD_URL = 'https://octopus-mex.fintopia.tech/api-web/hostCond';
const PHI_PROD_URL = 'https://octopus-pa.fintopia.tech/api-web/hostCond';
const INDO_PROD_URL = 'https://octopus.easycash.id/api-web/hostCond';
const SEA_PROD_URL = 'https://octopus-sea.fintopia.tech/api-web/hostCond';
const urlMap = {
    'test': TEST_URL,
    'prod': CN_PROD_URL,
    'prod-mex': MEX_PROD_URL,
    'prod-phi': PHI_PROD_URL,
    'prod-indo': INDO_PROD_URL,
    'prod-sea': SEA_PROD_URL,
};
const getConfigCenterUrl = (stage) => {
    if (stage === 'dev')
        return TEST_URL;
    return urlMap[stage];
};
const handleResponsePrefix = (prefix, raw) => {
    const keys = Object.keys(raw);
    return keys.reduce((accu, curr) => {
        const exp = new RegExp(`^${prefix}.`);
        const key = curr.replace(exp, '');
        return Object.assign(Object.assign({}, accu), { [key]: raw[curr] });
    }, {});
};
const customizeConfigCenter = (params, defaultValue = {}) => () => {
    const finallyParams = Object.assign({}, defaultParams, params);
    const { stage, url: configCenterUrl } = finallyParams, queryParams = __rest(finallyParams, ["stage", "url"]);
    const url = configCenterUrl || getConfigCenterUrl(stage);
    return new Promise(resolve => {
        if (!url)
            return resolve(defaultValue);
        const queryString = new URLSearchParams(queryParams);
        fetch(`${url}?${queryString}`)
            .then(response => {
            if (!response.ok)
                return resolve(defaultValue);
            return response.json();
        })
            .then(json => {
            var _a;
            if (((_a = json === null || json === void 0 ? void 0 : json.status) === null || _a === void 0 ? void 0 : _a.code) !== 0)
                return resolve(defaultValue);
            const value = json.body || defaultValue;
            const ret = finallyParams.prefix
                ? handleResponsePrefix(finallyParams.prefix, value)
                : value;
            resolve(ret);
        })
            .catch(() => resolve(defaultValue));
    });
};
export default customizeConfigCenter;
