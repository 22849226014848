import ReportStrategy from './strategy';
// const getOfflineTimeout = (radio = 4) => {
//     const cacheOfflineTimeout = window.localStorage.getItem('_ytalk_log_offline_timeout');
//     let offlineTimeout = cacheOfflineTimeout ? +cacheOfflineTimeout : 0;
//     offlineTimeout = offlineTimeout % 7;
//     offlineTimeout = offlineTimeout + 1;
//     window.localStorage.setItem('_ytalk_log_offline_timeout', `${offlineTimeout}`);
//     return offlineTimeout * radio;
// };
// const offlineTimeout = getOfflineTimeout();
class YtalkMetric {
    constructor(options) {
        this.apply = (options) => {
            const { ytalk, hubService } = options;
            hubService.getClient('sessionClient', (client) => {
                this.sessionClient = client.getClient();
                this.listenSession();
            });
            hubService.getClient('messageClient', (client) => {
                this.messageClient = client.getClient();
                this.listenMessage();
            });
            hubService.getClient('socketClient', (client) => {
                this.socketClient = client.getClient();
                this.listenSocket();
            });
            const { stage, country, url } = this.options;
            this.exception = new ReportStrategy({
                limitRate: 1,
                stage,
                country,
                url,
                dbName: 'ytalk_exception',
                tbName: 'exception',
                version: 1,
                limit: 5,
                getDefaultData: this.options.getDefaultData,
            }, {
                appId: 'web_ytalk_exception',
                appVersion: ytalk.getVersion(),
                measurement: 'app_metrics_for_web_ytalk_exception',
            });
            this.rtpMetric = new ReportStrategy({
                limitRate: 1,
                stage,
                country,
                url,
                dbName: 'ytalk',
                tbName: 'rtpMetric',
                version: 1,
                limit: 3,
                getDefaultData: this.options.getDefaultData,
            }, {
                appId: 'web_ytalk_rpt',
                appVersion: ytalk.getVersion(),
                measurement: 'app_metrics_for_web_ytalk_rtp',
            });
            this.networkMetric = new ReportStrategy({
                limitRate: 0.1,
                stage,
                country,
                url,
                dbName: 'ytalk_network',
                tbName: 'network',
                version: 1,
                limit: 3,
                getDefaultData: this.options.getDefaultData,
            }, {
                appId: 'web_ytalk_network',
                appVersion: ytalk.getVersion(),
                measurement: 'app_metrics_for_web_ytalk_network',
            });
        };
        this.rtpStats = ({ session, stats }) => {
            const { uuid: sessionUuid, callUuid, callType, connected } = session;
            const { input, output } = stats;
            if (!this.rtpMetric)
                return;
            const data = {
                sessionUuid,
                callUuid,
                callType,
                connected,
                input,
                output,
                type: 'RTP_STATS',
            };
            this.rtpMetric.report(data);
        };
        this.networkReport = (data = {}) => {
            if (!this.networkMetric)
                return;
            this.networkMetric.report(Object.assign({}, {
                sc: '-1',
                sl: '-1',
                sr: '-1',
                srr: '-1',
                rl: '-1',
                rr: '-1',
                rrr: '-1',
            }, data));
        };
        this.loginCheck = ({ reason }) => {
            this.exceptionReport({ type: 'ABNORMAL_LOGOUT', reason });
        };
        this.handleSessionException = ({ session, reason, message }) => {
            const { uuid: sessionUuid, callUuid, callType, connected } = session;
            this.exceptionReport({
                type: 'SESSION_EXCEPTION',
                sessionUuid,
                callUuid,
                callType,
                connected: connected === null || connected === void 0 ? void 0 : connected.toString(),
                reason,
                message,
            });
        };
        this.handleSocketSendMessageFail = ({ message, reason }) => {
            const { method, id, callId, statusCode, ignoreCollection } = message;
            if (ignoreCollection)
                return;
            this.exceptionReport({
                type: 'WEBSOCKET_SEND_MESSAGE_FAIL',
                reason,
                sipMethod: method,
                sipBranchId: id,
                sipCallId: callId,
                sipCode: statusCode,
            });
        };
        this.handleSocketDisconnected = ({ reason, closeInfo, registerCallId }) => {
            const { code, wasClean, reason: socketReason } = closeInfo || {};
            this.exceptionReport({
                type: 'WEBSOCKET_DISCONNECTED',
                reason,
                socketCode: code,
                socketWasClean: wasClean === null || wasClean === void 0 ? void 0 : wasClean.toString(),
                socketReason,
                registerCallId,
            });
        };
        this.handleSdkError = ({ reason, message } = {}) => {
            this.exceptionReport({
                type: 'SDK_ERROR',
                reason,
                message,
            });
        };
        this.handleNetworkQuality = (data) => {
            var _a;
            this.networkReport(Object.assign(Object.assign({}, ((data === null || data === void 0 ? void 0 : data.quality) || {})), { sc: (_a = data === null || data === void 0 ? void 0 : data.quality) === null || _a === void 0 ? void 0 : _a.score, type: 'NETWORK_QUALITY' }));
        };
        this.listenSocket = () => {
            if (!this.socketClient)
                return;
            this.socketClient.addListener('SEND_MESSAGE_FAIL', this.handleSocketSendMessageFail);
            this.socketClient.addListener('DISCONNECTED', this.handleSocketDisconnected);
            this.socketClient.addListener('SDK_ERROR', this.handleSdkError);
        };
        this.removeSocket = () => {
            if (!this.socketClient)
                return;
            this.socketClient.removeListener('SEND_MESSAGE_FAIL', this.handleSocketSendMessageFail);
            this.socketClient.removeListener('DISCONNECTED', this.handleSocketDisconnected);
            this.socketClient.removeListener('SDK_ERROR', this.handleSdkError);
        };
        this.listenSession = () => {
            if (!this.sessionClient)
                return;
            this.sessionClient.addListener('RTP_STATS_END', this.rtpStats);
            this.sessionClient.addListener('SESSION_EXCEPTION', this.handleSessionException);
            this.sessionClient.addListener('NETWORK_QUALITY_CHANGE', this.handleNetworkQuality);
        };
        this.removeSession = () => {
            if (!this.sessionClient)
                return;
            this.sessionClient.removeListener('RTP_STATS_END', this.rtpStats);
            this.sessionClient.removeListener('SESSION_EXCEPTION', this.handleSessionException);
            this.sessionClient.removeListener('NETWORK_QUALITY_CHANGE', this.handleNetworkQuality);
        };
        this.listenMessage = () => {
            if (!this.messageClient)
                return;
            this.messageClient.addListener('LOGIN_CHECK', this.loginCheck);
        };
        this.removeMessage = () => {
            if (!this.messageClient)
                return;
            this.messageClient.removeListener('LOGIN_CHECK', this.loginCheck);
        };
        this.destroy = () => {
            this.removeSession();
            this.removeMessage();
            this.removeSocket();
        };
        this.options = Object.assign({}, {
            getDefaultData: () => ({})
        }, options);
    }
    exceptionReport(data = {}) {
        if (!this.exception)
            return;
        this.exception.report(Object.assign({
            type: '-1',
            reason: '-1',
            message: '-1',
            sessionUuid: '-1',
            callUuid: '-1',
            callType: '-1',
            callStatus: '-1',
            connected: '-1',
            socketCode: '-1',
            socketWasClean: '-1',
            socketReason: '-1',
            duration: '-1',
            registerCallId: '-1',
            localRegisterCallId: '-1',
            remoteRegisterCallId: '-1',
            pageId: '-1',
            browserId: '-1',
            devicePageId: '-1',
            device: '-1',
            inCalling: '-1',
            behavior: '-1',
            sipMethod: '-1',
            sipBranchId: '-1',
            sipCallId: '-1',
            sipCode: '-1',
        }, data));
    }
}
export default YtalkMetric;
