/**
 * @author xinzhong
 * @email xinzhong@yangqianguan.com
 * @date 2020-11-25 16:16:08.762
 * @desc generated by yqg-cli@1.3.1-alpha.6
 * 我们在引用路由的时候，尽量保证通过 name 去引用
 * 一旦一个路由的 name 被确定之后，理论上来说，不应该再去改变它，它标志着这个页面的功能和角色
 */

/* global __RECONCILE_PAGE__ */

import BusinessName from 'collection-admin-web/common/constant/business-name';
import PermissionTypes, {PermissionMap} from 'collection-admin-web/common/constant/permission-type';
import * as RouterName from 'collection-admin-web/common/constant/router-name';
import RouterLoadingLayout from 'collection-admin-web/common/layout/router-loading';

const customLayout = true;
const customContentHead = true;
const customBreadcrumb = true;
const hideInSider = true;
const hideSider = true;
const hideInBreadcrumb = true;

const component = {template: '<router-view />'};

const COOLCOLLEGE_HOST = 'https://fintopia.coolcollege.cn';

function lazyLoadView(AsyncView) {
    const AsyncHandler = () => ({
        component: AsyncView,
        loading: RouterLoadingLayout
    });

    return Promise.resolve({
        functional: true,
        render(createElement, {data, children}) {
            return createElement(AsyncHandler, data, children);
        }
    });
}

export default [
    {
        path: '/',
        redirect: {name: 'index'}
    },
    {
        path: '*',
        redirect: {name: 'index'}
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('collection-admin-web/app/index'),
        meta: {
            navTitle: '首页',
            yqgPrivileges: []
        }
    },
    {
        path: '/cockpit',
        name: RouterName.Cockpit,
        component: () => import('collection-admin-web/app/cockpit'),
        meta: {
            navTitle: '驾驶舱',
            yqgPrivileges: [
                PermissionMap.WORK_DASHBOARD_QUERY
            ],
            customContentHead,
            customBreadcrumb
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('collection-admin-web/app/login'),
        beforeEnter(to, from, next) {
            Object.assign(to.params, {name: from.name || 'index'});
            Object.assign(to.query, from.query);

            next();
        },
        meta: {
            navTitle: '登录',
            hideSider,
            hideInSider,
            hideInBreadcrumb,
            customContentHead
        }
    },
    {
        path: '/bubble-login',
        name: 'bubbleLogin',
        component: () => import('collection-admin-web/app/bubble-login'),
        meta: {
            hideInSider,
            customLayout,
            navTitle: '冒泡登录'
        }
    },
    {
        path: '/we-chat',
        name: 'WeChat',
        component: () => import('collection-admin-web/app/we-chat'),
        meta: {
            hideInSider,
            customLayout,
            navTitle: '添加微信'
        }
    },
    {
        path: '/modify-password',
        name: 'modifyPassword',
        component: () => import('collection-admin-web/app/modify-password'),
        meta: {
            hideInSider,
            navTitle: '修改密码',
            yqgPrivileges: []
        }
    },
    // 预览md的页面
    {
        path: '/preview-md',
        name: 'previewMd',
        component: () => import('collection-admin-web/app/preview-md'),
        meta: {
            customContentHead: true,
            yqgPrivileges: []
        }
    },
    {
        path: '/case',
        name: 'case',
        component,
        meta: {
            icon: 'book',
            navTitle: '案件管理',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'repayment-guide',
                name: 'repaymentGuide',
                component: () => lazyLoadView(import('collection-admin-web/app/case/repayment-guide')),
                meta: {
                    navTitle: '还款推荐',
                    hideInSider,
                    hideSider,
                    hideInBreadcrumb,
                    customContentHead,
                    customLayout,
                    yqgPrivileges: [

                    ]
                }
            },
            {
                path: 'assign',
                name: 'caseAssign',
                component: () => lazyLoadView(import('collection-admin-web/app/case/assign')),
                meta: {
                    navTitle: '分案管理',
                    yqgPrivileges: [
                        PermissionTypes.ASSIGN.CONFIG.QUERY
                    ]
                }
            },
            {
                path: 'rule-code',
                name: 'ruleCode',
                component: () => lazyLoadView(import('collection-admin-web/app/case/rule-code')),
                meta: {
                    navTitle: '规则码分案',
                    yqgPrivileges: [
                        PermissionMap.RULE_CODE_QUERY
                    ]
                }
            },
            {
                path: 'rule-code-record',
                name: RouterName.RuleCodeRecord,
                component: () => lazyLoadView(import('collection-admin-web/app/case/rule-code-record')),
                meta: {
                    navTitle: '规则码分案记录',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionMap.ASSIGN_RECORD_QUERY
                    ]
                }
            },
            {
                path: 'rule-code-relation',
                name: RouterName.RuleCodeRelation,
                component: () => lazyLoadView(import('collection-admin-web/app/case/rule-code-relation')),
                meta: {
                    navTitle: '规则码案件互查(当日)',
                    yqgPrivileges: [
                        PermissionMap.CASE_RULE_RELATION_QUERY
                    ]
                }
            },
            {
                path: 'assign-record',
                name: 'caseAssignRecord',
                component: () => lazyLoadView(import('collection-admin-web/app/case/assign-record')),
                meta: {
                    navTitle: '分案记录',
                    yqgPrivileges: [
                        PermissionMap.ASSIGN_RECORD_QUERY
                    ]
                }
            },
            {
                path: 'relative',
                name: RouterName.CaseRelative,
                component: () => lazyLoadView(import('collection-admin-web/app/case/relative')),
                meta: {
                    navTitle: '辖内委案',
                    yqgPrivileges: [
                        PermissionMap.CASE_RELATIVE_QUERY
                    ]
                }
            },
            {
                path: 'relative-new',
                name: RouterName.CaseRelativeNew,
                component: () => lazyLoadView(import('collection-admin-web/app/case/relative-new')),
                meta: {
                    navTitle: '辖内委案(新)',
                    yqgPrivileges: [
                        PermissionMap.CASE_RELATIVE_QUERY_NEW
                    ]
                }
            },
            {
                path: 'relative-remind',
                name: RouterName.CaseRelativeRemind,
                component: () => lazyLoadView(import('collection-admin-web/app/case/relative-remind')),
                meta: {
                    navTitle: '辖内催告委案',
                    yqgPrivileges: [
                        PermissionTypes.REMIND.CASE.RELATIVE.QUERY
                    ],
                    yqgBusinessName: [BusinessName.YQG]
                }
            },
            {
                path: 'lawsuit',
                name: RouterName.CaseLawsuit,
                component: () => lazyLoadView(import('collection-admin-web/app/case/lawsuit')),
                meta: {
                    navTitle: '诉讼委案',
                    yqgPrivileges: [
                        PermissionMap.LAWSUIT_RELATIVE_QUERY_LIST
                    ]
                }
            },
            {
                path: 'lawsuit-detail',
                name: RouterName.CaseLawsuitDetail,
                component: () => lazyLoadView(import('collection-admin-web/app/case/lawsuit-detail')),
                meta: {
                    navTitle: '诉讼详情',
                    hideInSider,
                    highlightRouterName: RouterName.CaseLawsuit,
                    yqgPrivileges: [
                        PermissionMap.LAWSUIT_RELATIVE_QUERY_DETAIL
                    ]
                }
            },
            {
                path: 'own',
                name: 'caseOwn',
                component: () => lazyLoadView(import('collection-admin-web/app/case/own')),
                meta: {
                    navTitle: '我的委案',
                    yqgPrivileges: [
                        PermissionMap.CASE_OWN_QUERY
                    ]
                }
            },
            {
                path: 'own-new',
                name: 'caseOwnNew',
                component: () => lazyLoadView(import('collection-admin-web/app/case/own-new')),
                meta: {
                    navTitle: '我的委案(新)',
                    yqgPrivileges: [
                        PermissionMap.CASE_OWN_QUERY_NEW
                    ]
                }
            },
            {
                path: 'my-workspace',
                name: 'myWorkspace',
                component: () => import('collection-admin-web/app/case/my-workspace'),
                meta: {
                    navTitle: '我的工作台',
                    yqgPrivileges: [
                        PermissionMap.WORKSPACE_QUERY
                    ]
                }
            },
            {
                path: 'detail',
                name: RouterName.CaseDetail,
                component: () => import('collection-admin-web/app/case/detail'),
                meta: {
                    navTitle: '案件详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.CASE.DETAIL.QUERY.self
                    ]
                }
            },
            {
                path: 'remind-detail',
                name: RouterName.RemindCaseDetail,
                component: () => import('collection-admin-web/app/case/remind-detail'),
                meta: {
                    navTitle: '催告详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.REMIND.CASE.DETAIL.QUERY
                    ],
                    yqgBusinessName: [BusinessName.YQG]
                }
            },
            {
                path: 'relative-assist',
                name: 'relativeAssist',
                component: () => lazyLoadView(import('collection-admin-web/app/case/relative-assist')),
                meta: {
                    navTitle: '辖内协催',
                    yqgPrivileges: [
                        PermissionTypes.CASE.RELATIVE.ASSIST.COLLECTION
                    ]
                }
            },
            {
                path: 'own-assist',
                name: 'ownAssist',
                component: () => lazyLoadView(import('collection-admin-web/app/case/own-assist')),
                meta: {
                    navTitle: '我的协催',
                    yqgPrivileges: [
                        PermissionTypes.CASE.DETAIL.ASSIST.COLLECTION
                    ]
                },
                redirect: {name: RouterName.CaseOwnAssistStart},
                children: [
                    {
                        path: 'start',
                        name: RouterName.CaseOwnAssistStart,
                        component: () => import('collection-admin-web/app/case/own-assist/start'),
                        meta: {
                            navTitle: '我发起的协催',
                            highlightRouterName: 'ownAssist',
                            yqgPrivileges: [
                                PermissionTypes.CASE.DETAIL.ASSIST.COLLECTION
                            ]
                        }
                    },
                    {
                        path: 'received',
                        name: RouterName.CaseOwnAssistReceived,
                        component: () => import('collection-admin-web/app/case/own-assist/received'),
                        meta: {
                            navTitle: '我收到的协催',
                            highlightRouterName: 'ownAssist',
                            yqgPrivileges: [
                                PermissionTypes.CASE.DETAIL.ASSIST.COLLECTION
                            ]
                        }
                    }
                ]
            },
            {
                path: 'remind',
                name: RouterName.CaseRemind,
                component: () => lazyLoadView(import('collection-admin-web/app/case/remind')),
                meta: {
                    navTitle: '委案提醒',
                    yqgPrivileges: [
                        PermissionTypes.NOTIFICATION.QUERY
                    ]
                }
            },
            {
                path: 'repayment',
                name: 'repayment',
                component: () => lazyLoadView(import('collection-admin-web/app/case/repayment')),
                meta: {
                    navTitle: '还款记录',
                    yqgPrivileges: [
                        PermissionTypes.CASE.REPAYMENT.QUERY
                    ]
                }
            },
            {
                path: 'search-collection',
                name: 'searchCollection',
                component: () => lazyLoadView(import('collection-admin-web/app/case/search-collection')),
                meta: {
                    navTitle: '催记查询',
                    yqgPrivileges: [
                        PermissionTypes.CASE.FLOW.QUERY
                    ]
                }
            },
            {
                path: 'feedback-record',
                name: RouterName.FeedbackRecord,
                component: () => lazyLoadView(import('collection-admin-web/app/case/feedback-record')),
                meta: {
                    navTitle: '工单反馈记录',
                    yqgPrivileges: [
                        PermissionMap.CASE_FEEDBACK_FLOW_RELATIVE_QUERY
                    ]
                }
            },
            {
                path: 'complaint-classification',
                name: 'complaintClassification',
                component: () => lazyLoadView(import('collection-admin-web/app/case/complaint-classification')),
                meta: {
                    navTitle: '撤案查询',
                    yqgPrivileges: [
                        PermissionMap.SENSITIVE_WORDS_QUERY
                    ]
                }
            },
            {
                path: 'demand-letter',
                name: 'demandLetter',
                component: () => lazyLoadView(import('collection-admin-web/app/case/demand-letter')),
                meta: {
                    navTitle: '律师函查询',
                    yqgPrivileges: [
                        PermissionTypes.DEMAND.LETTER.QUERY
                    ]
                }
            },
            {
                path: 'withhold-record',
                name: 'withholdRecord',
                component: () => lazyLoadView(import('collection-admin-web/app/case/withhold-record')),
                meta: {
                    navTitle: '代扣记录查询',
                    yqgPrivileges: [
                        PermissionTypes.WITHHOLD.FLOW.DETAIL.QUERY
                    ]
                }
            },
            {
                path: 'app-login-history',
                name: RouterName.CaseAppLoginHistory,
                component: () => import('collection-admin-web/app/case/app-login-history'),
                meta: {
                    navTitle: 'APP登录历史',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.NOTIFICATION.QUERY
                    ]
                }
            },
            {
                path: 'wechat-record',
                name: 'wechatRecord',
                component: () => import('collection-admin-web/app/case/wechat-record'),
                meta: {
                    navTitle: '企业微信记录',
                    yqgPrivileges: [
                        PermissionMap.ENTERPRISE_WECHAT_RELATIVE_EXTERNAL_USERS_QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/statistic',
        name: 'statistic',
        component,
        meta: {
            icon: 'area-chart',
            navTitle: '统计报表',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'collector',
                name: 'collector',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/collector')),
                meta: {
                    navTitle: '催收员工作情况',
                    yqgPrivileges: [
                        PermissionTypes.CALL.PERFORMANCE.SELF.QUERY,
                        PermissionTypes.CALL.PERFORMANCE.RELATIVE.QUERY,
                        PermissionTypes.CASE.PERFORMANCE.SELF.QUERY,
                        PermissionTypes.CASE.PERFORMANCE.RELATIVE.QUERY,
                        PermissionTypes.MONTHLY.PERFORMANCE.SELF.QUERY,
                        PermissionTypes.MONTHLY.PERFORMANCE.RELATIVE.QUERY
                    ]
                }
            },
            {
                path: 'company',
                name: 'company',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/company')),
                meta: {
                    navTitle: '机构绩效统计',
                    yqgPrivileges: [
                        PermissionTypes.AREA.COLLECTION.RATE.QUERY
                    ]
                }
            },
            {
                path: 'predict',
                name: 'predict',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/predict')),
                meta: {
                    navTitle: '预测外呼实时分案',
                    yqgPrivileges: [
                        PermissionTypes.AUTO.DIAL.ASSIGNMENT.SUMMERY
                    ]
                }
            },
            {
                path: 'call-record',
                name: 'callRecord',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/call-record')),
                meta: {
                    navTitle: '外呼统计',
                    yqgPrivileges: [
                        PermissionTypes.CALL.SUMMARY.QUERY
                    ]
                }
            },
            {
                path: 'quality',
                name: 'quality',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/quality')),
                meta: {
                    navTitle: '质检报表',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.REPORT.MANUAL,
                        PermissionTypes.CALL.QUALITY.REPORT.ANALYZE,
                        PermissionTypes.CALL.QUALITY.REPORT.MACHINE.FAIL.REASON,
                        PermissionTypes.CALL.QUALITY.REPORT.MANUAL.FAIL.REASON,
                        PermissionTypes.CALL.QUALITY.REPORT.AUDIT,
                        PermissionTypes.CALL.QUALITY.REPORT.COMPANY,
                        PermissionTypes.CALL.QUALITY.REPORT.MISMATCH
                    ]
                }
            },
            {
                path: 'satisfaction',
                name: 'satisfaction',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/satisfaction')),
                meta: {
                    navTitle: '满意度评价',
                    yqgPrivileges: [
                        PermissionTypes.AREA.SATISFACTION.RATE.QUERY
                    ]
                }
            },
            {
                path: 'audit-monitor',
                name: RouterName.AuditMonitor,
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/audit-monitor')),
                meta: {
                    navTitle: '稽核工作量监控',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.REPORT.AUDIT.MONITOR
                    ]
                }
            },
            {
                path: 'complaint-monitor',
                name: RouterName.ComplaintMonitor,
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/complaint-monitor')),
                meta: {
                    navTitle: '投诉工作量监控',
                    yqgPrivileges: [
                        PermissionMap.COMPLAINT_PERFORMANCE_QUERY
                    ]
                }
            },
            {
                path: 'withhold',
                name: 'withhold',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/withhold')),
                meta: {
                    navTitle: '代扣统计',
                    yqgPrivileges: [
                        PermissionTypes.WITHHOLD.FLOW.REPORT.QUERY
                    ]
                }
            },
            {
                path: 'wechat-statistic',
                name: 'wechatStatistic',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/wechat-statistic')),
                meta: {
                    navTitle: '微信发起率统计',
                    yqgPrivileges: [
                        PermissionTypes.ENTERPRISE.WECHAT.REPORT.QUERY
                    ]
                }
            },
            {
                path: 'wechat-add-user',
                name: 'wechatAddUser',
                component: () => lazyLoadView(import('collection-admin-web/app/statistic/wechat-add-user')),
                meta: {
                    navTitle: '添加用户好友记录',
                    yqgPrivileges: [
                        PermissionTypes.ENTERPRISE.WECHAT.ADD.USER.FLOW.QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/call',
        name: 'call',
        component,
        meta: {
            icon: 'phone',
            navTitle: '外呼管理',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'record',
                name: RouterName.CallRecord,
                component: () => lazyLoadView(import('collection-admin-web/app/call/record')),
                meta: {
                    navTitle: '录音查询',
                    yqgPrivileges: [
                        PermissionTypes.CALL.DETAIL.QUERY.self
                    ]
                }
            },
            {
                path: 'call-monitor',
                name: 'callMonitor',
                component: () => lazyLoadView(import('collection-admin-web/app/call/call-monitor')),
                meta: {
                    navTitle: '空闲催收员监控',
                    yqgPrivileges: [
                        PermissionTypes.CALL.VACANT.COLLECTOR
                    ]
                }
            },
            {
                path: 'process',
                name: 'process',
                component: () => lazyLoadView(import('collection-admin-web/app/call/process')),
                meta: {
                    navTitle: '预测外呼进度',
                    yqgPrivileges: [
                        PermissionTypes.AUTO.DIAL.PROGRESS.QUERY
                    ]
                }
            },
            {
                path: 'call-out',
                name: 'callOut',
                component: () => lazyLoadView(import('collection-admin-web/app/call/call-out')),
                meta: {
                    navTitle: '外呼查询',
                    yqgPrivileges: [
                        PermissionMap.CALL_QUERY_CALL_INFO
                    ]
                }
            }
        ]
    },
    {
        path: '/call-quality',
        name: 'callQuality',
        component,
        meta: {
            icon: 'security-scan',
            navTitle: '外呼质检',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'relative',
                name: 'qualityRelative',
                component: () => lazyLoadView(import('collection-admin-web/app/call-quality/relative')),
                meta: {
                    navTitle: '辖内质检',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.RELATIVE.QUERY
                    ]
                }
            },
            {
                path: 'own',
                name: 'qualityOwn',
                component: () => lazyLoadView(import('collection-admin-web/app/call-quality/own')),
                meta: {
                    navTitle: '我的质检',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.OWN.QUERY
                    ]
                }
            },
            {
                path: 'complaint',
                name: 'qualityComplaint',
                component: () => lazyLoadView(import('collection-admin-web/app/call-quality/complaint')),
                meta: {
                    navTitle: '投诉质检',
                    yqgPrivileges: [
                        PermissionMap.COMPLAINT_QUALITY_QUERY
                    ]
                }
            },
            {
                path: 'repair',
                name: 'qualityRepair',
                component: () => lazyLoadView(import('collection-admin-web/app/call-quality/repair')),
                meta: {
                    navTitle: '录音质检',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.QUERY
                    ]
                }
            },
            {
                path: 'repair-detail',
                name: RouterName.RepairQuailtyDetail,
                component: () => import('collection-admin-web/app/call-quality/repair-detail'),
                meta: {
                    navTitle: '录音质检详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.QUERY
                    ]
                }
            },
            {
                path: 'complaint-detail',
                name: RouterName.ComplaintQualityDetail,
                component: () => import('collection-admin-web/app/call-quality/detail/index'),
                meta: {
                    navTitle: '投诉质检详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionMap.COMPLAINT_QUALITY_DETAIL_QUERY
                    ]
                }
            },
            {
                path: 'detail',
                name: RouterName.QualityDetail,
                component: () => import('collection-admin-web/app/call-quality/detail/index'),
                meta: {
                    navTitle: '质检详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.DETAIL.QUERY
                    ]
                }
            },
            {
                path: 'config',
                name: 'qualityConfig',
                component: () => lazyLoadView(import('collection-admin-web/app/call-quality/config')),
                meta: {
                    navTitle: '规则配置',
                    yqgPrivileges: [
                        PermissionTypes.CALL.QUALITY.CONFIG.QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/quality-review',
        name: 'qualityReview',
        component,
        meta: {
            icon: 'reconciliation',
            navTitle: '质检复议',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'list',
                name: 'qualityReviewList',
                component: () => lazyLoadView(import('collection-admin-web/app/quality-review/list')),
                meta: {
                    navTitle: '违规列表',
                    yqgPrivileges: [
                        PermissionTypes.PROCESS.FLOW.FAIL.QUERY
                    ]
                },
                redirect: {name: RouterName.QualityReviewListFail},
                children: [
                    {
                        path: 'fail',
                        name: RouterName.QualityReviewListFail,
                        component: () => import('collection-admin-web/app/quality-review/list/fail'),
                        meta: {
                            navTitle: '录音违规',
                            highlightRouterName: 'list',
                            yqgPrivileges: [
                                PermissionTypes.PROCESS.FLOW.FAIL.QUERY
                            ]
                        }
                    },
                    {
                        path: 'warn',
                        name: RouterName.QualityReviewListWarn,
                        component: () => import('collection-admin-web/app/quality-review/list/warn'),
                        meta: {
                            navTitle: '录音提醒',
                            highlightRouterName: 'list',
                            yqgPrivileges: [
                                PermissionTypes.PROCESS.FLOW.FAIL.QUERY
                            ]
                        }
                    },
                    {
                        path: 'code',
                        name: RouterName.QualityReviewListCode,
                        component: () => import('collection-admin-web/app/quality-review/list/code'),
                        meta: {
                            navTitle: '催记代码违规',
                            highlightRouterName: 'list',
                            yqgPrivileges: [
                                PermissionTypes.PROCESS.FLOW.FAIL.QUERY
                            ]
                        }
                    }
                ]
            },
            {
                path: 'flow',
                name: RouterName.QualityReviewFlow,
                component: () => lazyLoadView(import('collection-admin-web/app/quality-review/flow')),
                meta: {
                    navTitle: '复议流',
                    yqgPrivileges: [
                        PermissionTypes.PROCESS.FLOW.QUERY.self
                    ]
                }
            },
            {
                path: 'detail',
                name: RouterName.QualityReviewDetail,
                component: () => import('collection-admin-web/app/quality-review/detail'),
                meta: {
                    navTitle: '复议详情',
                    hideInSider,
                    yqgPrivileges: []
                }
            }
        ]
    },
    {
        path: '/advanced',
        name: 'advanced',
        component,
        meta: {
            icon: 'appstore',
            navTitle: '高级功能',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'announcement',
                name: 'announcement',
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/announcement')),
                meta: {
                    navTitle: '静态文案管理',
                    yqgPrivileges: [
                        PermissionTypes.STATIC.TEXT.QUERY
                    ]
                }
            },
            {
                path: 'feedback',
                name: 'feedback',
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/feedback')),
                meta: {
                    navTitle: '反馈列表',
                    yqgPrivileges: [
                        PermissionTypes.FEEDBACK.QUERY
                    ]
                }
            },
            {
                path: 'my-feedback',
                name: RouterName.MyFeedback,
                component: () => import('collection-admin-web/app/advanced/my-feedback'),
                meta: {
                    navTitle: '我的反馈',
                    hideInSider,
                    yqgPrivileges: []
                }
            },
            {
                path: 'bubble-query',
                name: 'bubbleQuery',
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/bubble-query')),
                meta: {
                    navTitle: '冒泡查询',
                    yqgPrivileges: [
                        PermissionTypes.ADMIN.USER.BUBBLE.QUERY
                    ]
                }
            },
            {
                path: 'schedule-manage',
                name: 'scheduleManage',
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/schedule-manage')),
                meta: {
                    navTitle: '排班管理',
                    yqgPrivileges: [
                        PermissionMap.ASSIGN_SCHEDULING_QUERY,
                        PermissionMap.SCHEDULING_QUERY
                    ]
                },
                children: [
                    {
                        path: 'assign-schedule',
                        name: RouterName.AssignSchedule,
                        component: () => lazyLoadView(import('collection-admin-web/app/advanced/schedule-manage/layout/assign-schedule')),
                        meta: {
                            navTitle: '排班',
                            yqgPrivileges: [
                                PermissionMap.ASSIGN_SCHEDULING_QUERY
                            ]
                        }
                    },
                    {
                        path: 'work-time',
                        name: RouterName.WorkTime,
                        component: () => lazyLoadView(import('collection-admin-web/app/advanced/schedule-manage/layout/work-time')),
                        meta: {
                            navTitle: '工作时间',
                            yqgPrivileges: [
                                PermissionMap.SCHEDULING_QUERY
                            ]
                        }
                    }
                ]
            },
            {
                path: 'work-status',
                name: 'workStatus',
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/work-status')),
                meta: {
                    navTitle: '工作状态',
                    yqgPrivileges: [
                        PermissionMap.WORK_STATUS_QUERY
                    ]
                }
            },
            {
                path: 'work-status-notification',
                name: RouterName.WorkStatusNotification,
                component: () => lazyLoadView(import('collection-admin-web/app/advanced/work-status-notification')),
                meta: {
                    navTitle: '状态提醒',
                    yqgPrivileges: [
                        PermissionMap.WORK_STATUS_NOTIFICATION
                    ]
                }
            }
        ]
    },
    {
        path: '/permission',
        name: 'permission',
        component,
        meta: {
            icon: 'lock',
            navTitle: '权限设置',
            yqgPrivileges: []
        },
        children: [
            {
                path: 'institution',
                name: 'institution',
                component: () => lazyLoadView(import('collection-admin-web/app/permission/institution')),
                meta: {
                    navTitle: '机构管理',
                    yqgPrivileges: [
                        PermissionTypes.AREA.QUERY.self
                    ]
                },
                redirect: {name: RouterName.PermissionInstitutionArea},
                children: [
                    {
                        path: 'area',
                        name: RouterName.PermissionInstitutionArea,
                        component: () => import('collection-admin-web/app/permission/institution/area'),
                        meta: {
                            navTitle: '机构管理',
                            highlightRouterName: 'institution',
                            yqgPrivileges: [
                                PermissionTypes.AREA.QUERY.self
                            ]
                        }
                    },
                    {
                        path: 'group',
                        name: RouterName.PermissionInstitutionGroup,
                        component: () => import('collection-admin-web/app/permission/institution/group'),
                        meta: {
                            navTitle: '机构树形图',
                            highlightRouterName: 'institution',
                            yqgPrivileges: [
                                PermissionTypes.AREA.QUERY.self
                            ]
                        }
                    }
                ]
            },
            {
                path: 'privilege',
                name: 'privilege',
                component: () => lazyLoadView(import('collection-admin-web/app/permission/privilege')),
                meta: {
                    navTitle: '权限管理',
                    yqgPrivileges: [
                        PermissionTypes.AREA.QUERY.self,
                        PermissionTypes.SUPER.ADMIN
                    ]
                },
                redirect: {name: RouterName.PermissionPrivilegeGroupManage},
                children: [
                    {
                        path: 'group',
                        name: RouterName.PermissionPrivilegeGroupManage,
                        component: () => import('collection-admin-web/app/permission/privilege/layout/group'),
                        meta: {
                            navTitle: '权限组管理',
                            highlightRouterName: 'privilege',
                            yqgPrivileges: [
                                PermissionTypes.AREA.QUERY.self,
                                PermissionTypes.SUPER.ADMIN
                            ]
                        }
                    },
                    {
                        path: 'point',
                        name: RouterName.PermissionPrivilegePointManage,
                        component: () => import('collection-admin-web/app/permission/privilege/layout/point'),
                        meta: {
                            navTitle: '权限点管理',
                            highlightRouterName: 'privilege',
                            yqgPrivileges: [
                                PermissionTypes.AREA.QUERY.self,
                                PermissionTypes.SUPER.ADMIN
                            ]
                        }
                    }
                ]
            },
            {
                path: 'account',
                name: RouterName.AccountManager,
                component: () => lazyLoadView(import('collection-admin-web/app/permission/account')),
                meta: {
                    navTitle: '账号管理',
                    yqgPrivileges: [
                        PermissionTypes.ADMIN.USER.QUERY
                    ]
                }
            },
            {
                path: 'account-apply-review',
                name: 'accountApplyReview',
                component: () => lazyLoadView(import('collection-admin-web/app/permission/account-apply-review')),
                meta: {
                    navTitle: '账号审批',
                    yqgPrivileges: [
                        PermissionTypes.PROCESS.FLOW.QUERY.CREATE.USER
                    ]
                }
            },
            {
                path: 'account-apply-detail',
                name: RouterName.AccountApplyDetail,
                component: () => import('collection-admin-web/app/permission/account-apply-detail'),
                meta: {
                    navTitle: '账号审批详情',
                    hideInSider,
                    yqgPrivileges: [
                        PermissionTypes.PROCESS.FLOW.DETAIL.CREATE.USER
                    ]
                }
            },
            {
                path: 'outsource-company',
                name: 'outsourceCompany',
                component: () => lazyLoadView(import('collection-admin-web/app/permission/outsource-company')),
                meta: {
                    navTitle: '职场管理',
                    yqgPrivileges: [
                        PermissionTypes.COMPANY.QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/config',
        name: 'commonConfig',
        component,
        meta: {
            icon: 'setting',
            navTitle: '配置管理',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'tag',
                name: 'tag',
                component: () => lazyLoadView(import('collection-admin-web/app/config/tag')),
                meta: {
                    navTitle: '标签配置',
                    yqgPrivileges: [
                        PermissionTypes.CASE.TAG.QUERY
                    ]
                }
            },
            {
                path: 'general',
                name: 'general',
                component: () => lazyLoadView(import('collection-admin-web/app/config/general')),
                meta: {
                    navTitle: '通用配置',
                    yqgPrivileges: [
                        PermissionTypes.CONFIGURATION.QUERY
                    ]
                }
            },
            {
                path: 'ivr',
                name: 'ivr',
                component: () => lazyLoadView(import('collection-admin-web/app/config/ivr')),
                meta: {
                    navTitle: 'IVR场景配置',
                    yqgPrivileges: [
                        PermissionTypes.IVR.SCENE.QUERY
                    ]
                }
            },
            {
                path: 'gate-keeper',
                name: 'gateKeeper',
                component: () => lazyLoadView(import('collection-admin-web/app/config/gate-keeper')),
                meta: {
                    navTitle: 'Gate Keeper',
                    yqgPrivileges: [
                        PermissionTypes.GATEKEEPER.QUERY
                    ]
                }
            },
            {
                path: 'area-tag',
                name: 'areaTag',
                component: () => lazyLoadView(import('collection-admin-web/app/config/area-tag')),
                meta: {
                    navTitle: '机构标签配置',
                    yqgPrivileges: [
                        PermissionTypes.AREA.TAG.QUERY
                    ]
                }
            },
            {
                path: 'business-type',
                name: 'businessType',
                component: () => lazyLoadView(import('collection-admin-web/app/config/business-type')),
                meta: {
                    navTitle: '业务类型配置',
                    yqgPrivileges: [
                        PermissionTypes.BUSINESS.QUERY
                    ],
                    yqgBusinessName: [BusinessName.SUPER]
                }
            },
            {
                path: 'cache-set',
                name: 'cacheSet',
                component: () => lazyLoadView(import('collection-admin-web/app/config/cache-set')),
                meta: {
                    navTitle: '缓存集合配置',
                    yqgPrivileges: [
                        PermissionTypes.CACHE.SET.QUERY
                    ]
                }
            },
            {
                path: 'stop-collection',
                name: 'stopCollection',
                component: () => lazyLoadView(import('collection-admin-web/app/config/stop-collection')),
                meta: {
                    navTitle: '停催管理',
                    yqgPrivileges: [
                        PermissionMap.BLACK_LIST_QUERY_ASSIGN
                    ]
                },
                redirect: {name: RouterName.StopCollectionAssign},
                children: [
                    {
                        path: 'assign',
                        name: RouterName.StopCollectionAssign,
                        component: () => import('collection-admin-web/app/config/stop-collection/layout/assign'),
                        meta: {
                            navTitle: '停分案',
                            highlightRouterName: 'stopCollection',
                            yqgPrivileges: [
                                PermissionMap.BLACK_LIST_QUERY_ASSIGN
                            ]
                        }
                    }
                ]
            },
            {
                path: 'job',
                name: 'job',
                component: () => lazyLoadView(import('collection-admin-web/app/config/job')),
                meta: {
                    navTitle: 'Job配置',
                    yqgPrivileges: [
                        PermissionTypes.JOB.QUERY
                    ]
                }
            },
            {
                path: 'message-template',
                name: 'message-template',
                component: () => lazyLoadView(import('collection-admin-web/app/config/message-template')),
                meta: {
                    navTitle: '短信模板配置',
                    yqgPrivileges: [
                        PermissionMap.MESSAGE_GROUP_QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/corp-wechat',
        name: 'corpWechat',
        component: () => import('collection-admin-web/app/corp-wechat'),
        meta: {
            navTitle: '企业微信h5',
            hideSider,
            hideInSider,
            customLayout,
            customContentHead
        },

        children: [
            {
                path: 'to-case-detail',
                name: 'toCaseDetail',
                component: () => import('collection-admin-web/app/corp-wechat/to-case-detail'),
                meta: {
                    navTitle: '跳转案件详情',
                    hideSider,
                    hideInSider,
                    customLayout,
                    customContentHead
                }
            },
            {
                path: 'make-collection',
                name: 'makeCollection',
                component: () => import('collection-admin-web/app/corp-wechat/make-collection'),
                meta: {
                    yqgPrivileges: [],
                    navTitle: '发起收款',
                    hideSider,
                    hideInSider,
                    customLayout,
                    customContentHead
                },
            }
        ]
    },
    {
        path: '/knowledge',
        name: 'knowledge',
        component,
        meta: {
            icon: 'book',
            navTitle: '知识库',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'application',
                name: 'application',
                component: () => import('collection-admin-web/app/knowledge/application'),
                meta: {
                    navTitle: '知识库查询',
                    yqgPrivileges: [
                        PermissionMap.KNOWLEDGE_TEXT_QUERY
                    ]
                }
            },
            {
                path: COOLCOLLEGE_HOST,
                name: 'CoolCollege',
                meta: {
                    navTitle: '在线考试',
                    external: true
                }
            }
        ]
    },
    {
        path: '/customer-complaint',
        name: 'customer-complaint',
        component,
        meta: {
            icon: 'reconciliation',
            navTitle: '客诉管理',
            yqgPrivileges: []
        },

        children: [
            {
                path: 'query',
                name: 'query',
                component: () => import('collection-admin-web/app/customer-complaint/query'),
                meta: {
                    navTitle: '联系记录查询',
                    yqgPrivileges: [
                        PermissionMap.CONNECT_HISTORY_QUERY
                    ]
                }
            }
        ]
    },
    {
        path: '/agreement-approve',
        name: 'agreementApprove',
        component: () => import('collection-admin-web/app/agreement-approve'),
        meta: {
            navTitle: '催收系统调解协议审批',
            hideSider,
            hideInSider,
            customLayout,
            customContentHead,
            yqgPrivileges: []
        }
    },
    {
        path: '/reconcile',
        name: 'reconcile',
        component,
        meta: {
            icon: 'cloud-download',
            navTitle: '调解资料导出',
            yqgPrivileges: [
                PermissionMap.CASE_RELATIVE_EXPORT_LIST
            ]
        },
        children: [
            {
                path: 'list',
                name: 'list',
                component: () => import('collection-admin-web/app/reconcile/list/index'),
                meta: {
                    navTitle: '调解资料导出',
                    yqgPrivileges: [
                        PermissionMap.CASE_RELATIVE_EXPORT_LIST
                    ],
                    isMicro: true,
                    pageUrl: __RECONCILE_PAGE__
                }
            }
        ]
    }
];
