/**
 * @Author: giligili
 * @Date: 2022-12-16
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-26 18:35:57
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import Ytalk from 'collection-admin-web/common/resource/call/ytalk';

const {YQG} = EnumAll.Call.Channel.TYPE;
const BooleanType = EnumAll.Basic.Boolean.TYPE;

function getCaseDetail() {
    const {title, encryptedMobileNumber, callDirection} = this;
    const {gatewayCode} = this.inviteInfo;

    const {sharedDataRet} = this;

    return Ytalk.getCaseDetail({encryptedMobileNumber, gatewayCode})
        .then(({data: {body}}) => {
            const {
                callInManualAssign,
                caseAssigned,
                caseId,
                contactVerifyStatus = null,
                forceNoAssign = BooleanType.FALSE,
                message,
                name,
                relationship = null,
                sdkType,
                gatewayName,
                thirdPartyType,
                maskedMobileNumber
            } = body;

            sharedDataRet.resolve({
                maskedMobileNumber
            });

            const modalInfo = {sdkType, message, caseId};

            const contactInfo = {
                ...body,
                name,
                relationship,
                forceNoAssign,
                callDirection,
                contactVerifyStatus,
                thirdPartyType: thirdPartyType || YQG,
                encryptMobileNumber: encryptedMobileNumber,
                encryptedCollecteeMobile: encryptedMobileNumber
            };

            this.extraData.contactInfo = contactInfo;

            if (
                caseAssigned === BooleanType.TRUE
                || callInManualAssign === BooleanType.TRUE
            ) {
                return {
                    title,
                    link: true,
                    caseId,
                    message,
                    modalInfo,
                    contactInfo,
                    gatewayName
                };
            }

            return {link: false, caseId, message, gatewayName};
        });
}

export default {getCaseDetail};
