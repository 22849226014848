import Device from './device';
import { HubService } from './hub';
import Message from './message';
import Page from './page';
import Register from './register';
import SessionManage from './session';
import Socket from './socket';
class YTalk {
    constructor(options) {
        const { config } = options || {};
        this.clients = [];
        this.consumers = [];
        this.deviceClients = [];
        this.hubService = new HubService({ config });
        this.addClient(Page);
    }
    getVersion() {
        return YTalk.version;
    }
    isDevicePage() {
        const { device } = this;
        return !!(device && device._status === 1); // eslint-disable-line
    }
    addDevice(device) {
        if (this.device === device)
            return;
        this.destroyDeviceClient();
        this.device = device;
        this.addDeviceClient(Device);
        this.addDeviceClient(Socket);
        this.addDeviceClient(Register);
        this.addDeviceClient(Message);
        this.addDeviceClient(SessionManage);
    }
    removeDevice() {
        this.device = null;
    }
    addDeviceClient(ClientFactory, options = {}) {
        this.deviceClients.push(new ClientFactory(Object.assign({ hubService: this.hubService, device: this.device, ytalk: this }, options)));
    }
    addClient(ClientFactory, options = {}) {
        this.clients.push(new ClientFactory(Object.assign({ hubService: this.hubService, device: this.device, ytalk: this }, options)));
    }
    addConsumer(consumer) {
        consumer.apply({
            hubService: this.hubService,
            device: this.device,
            ytalk: this,
        });
        this.consumers.push(consumer);
    }
    /**
     * 先用 consumer 代替
     * 后续优化一下
     * 1. 和内部逻辑解耦, 在 feature 中把数据传给 client，这样 client 就不依赖 feature（不需要判空）
     */
    useFeature(feature) {
        this.addConsumer(feature);
    }
    destroyDeviceClient() {
        this.deviceClients.forEach((client) => {
            client.destroy();
        });
        this.deviceClients = [];
    }
    destroy() {
        this.clients.forEach((client) => {
            client.destroy();
        });
        this.clients = [];
        this.consumers.forEach((consumer) => {
            consumer.destroy();
        });
        this.consumers = [];
        this.destroyDeviceClient();
    }
}
YTalk.version = '0.0.1';
export default YTalk;
